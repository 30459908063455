import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {
  BlackButton,
} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Jaipur(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // document.title = "MyMover in Jaipur,Rajasthan";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setLoading(false);
      setCity(Object.keys(data)[0]);
    })

  }, []);

  if(loading) {
    return <Loading />
  }

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    } else if(city === "Jagatpura") { 
      props.history.push(`/packers-and-movers-in-jagatpura-jaipur`);
      return;
    } else if(city === "Vidhyadhar") { 
      props.history.push(`/packers-and-movers-in-vidhyadhar-jaipur`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-bangalore`);
      return;
    } else if (city === "Whitefield") {
      props.history.push(`/packers-and-movers-in-whitefield-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Helmet>
        <title>Best Packers and Movers in Jaipur | Save Upto 1000 on House Shifting Service</title>
        <meta name="description" content="Trusted movers and packers in Jaipur MyMovers provide efficient house shifting services for a safe, stress-free relocation. Trust us for timely and safe movement!" />
        <meta name="keywords" content="Jaipur, movers, packers, shifting, household" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packers and Movers in Jaipur | MyMovers Packers and Movers Your Trusted House Shifting Partner</h1>
        <h3><a href="https://mymovers.in">MyMovers.in</a> | Calculate movers and packers Charges in Jaipur</h3>
        <p>Jaipur, capital of Rajasthan state It seamlessly combines a rich cultural heritage with modern development. Jaipur is known 
          as "Pink City" due to its distinctive architectural style It attracts millions of tourists to landmarks such as the Hawa Mahal, 
          Amber Fort and City Palace... In addition to its historical charm Jaipur is also fast becoming a thriving urban centre. The city 
          has experienced significant growth in the IT, manufacturing and tourism industries. This makes the city an attractive spot for 
          employment and investment. Well-planned infrastructure including wide roads subway link and international airport Helps increase 
          the attractiveness of this city. Jaipur's growing residential and commercial real estate sector supports its growing population. 
          As startups and emerging industrial sectors drive economic progress The mix of tradition and innovation continues to make Jaipur 
          one of India's leading cities. It provides opportunities for businesses, residents and tourists.</p>

        <p>Relocating in a bustling city like Jaipur can be difficult without the help of experts. Our professional packers and drivers 
          ensure a smooth and stress-free experience while traveling within the city or anywhere else. Jaipur's expanding urban area and 
          diverse geography make transportation challenging. Professional drivers bring expertise in dealing with these difficult issues. 
          By providing a complete pick-up and delivery service. They use high-quality packaging materials and advanced techniques to ensure 
          the safety of your items. From fragile items to heavy furniture. Their trained team handles everything. Including packing, loading, 
          transporting, unloading and unpacking. This saves you valuable time and effort. Additionally, insurance provides peace of mind against 
          damages that may occur during transport. Navigating the busy roads of Jaipur and delivering on time can be daunting. But professional 
          packers and drivers can handle these tasks efficiently. Thanks to local expertise and well-organized processes... Hiring reliable 
          moving services in Jaipur not only reduces stress. But it also reduces risks such as property damage or delays. This makes it an 
          ideal choice for your residence, office, vehicle or move. Professional packers and drivers are indispensable in the growing city of 
          Jaipur for hassle-free and safe movement</p>

          <p>Although moving can be difficult, MyMovers Packers and Movers is here to make the process easy for you. As an experienced Jaipur 
            packers and movers. We offer reliable moving services. effective and reasonable price which is tailored to your needs. Our team of 
            professional drivers and packers in Jaipur guarantee a hassle-free move. Whether you move for work or go home. We take care of every 
            step with professionalism and care. From safe packing and transportation From packing and unpacking to your new address, our Jaipur 
            services aim to give you peace of mind by ensuring you arrive quickly and safely. Trust MyMovers Packers and Movers for a hassle-free 
            Jaipur moving experience. stress!</p>

          <h4>Why choose Professional Packers and Movers in Jaipur</h4>
          <p>Jaipur has emerged as one of the best sought-after domestic markets in Jaipur. With the presence of leading IT companies Luxurious 
            country complex and good infrastructure, Jaipur has attracted many professionals and families who want to live in the thriving neighborhood. 
            Whether you're moving your home or office, the professionals Packers want in Jaipur cannot be overstated. </p>
            
            1. Expertise in Local Relocations 
            <p>Jaipur's busy streets and the complexity of the town's layout Putting the rest of the work in style for the professionals moving to or from 
              this area, MyMovers Packers and Movers specialize in traditional moving management in Jaipur that will make your move smooth, safe and hassle-free. 
              Our in-depth knowledge of unique routes, business models, and regulations may affect your movement. This allows us to reduce inhibitions and 
              provide excellent service. </p>
            2. Comprehensive Moving Solutions
            <p>Moving is not just about moving details from one place to another. This includes careful quilting, moving, loading and unloading. This is where 
              MyMovers excels. As durable movers and packers in Jaipur, we offer comprehensive moving results that Covers all aspects of the relocation process. 
              Whether you need help packing fragile details Cabinet disassembly or organizing the pantry Our platoon is ready to help. </p>
            3. Stress-Free and Time-Efficient
            <p>The last thing you want while on the move is free stress. It can take weeks or months of planning and execution to handle the logistics, quilting, 
              and shipping yourself. MyMovers Packers and Movers in Jaipur will do all the heavy lifting. Helping you focus on moving to your new home or office. 
              Our efficient platoon ensures that the move is completed within the stipulated time. without affecting the quality of service.</p>

          <h4>MyMovers Packers and Movers Our Services </h4>
          <p>At MyMovers Packers and Movers we honor ourselves by providing a wide range of trained services to meet the unique needs of each client. As a premier 
            packer and mover. Leading in Jaipur We cater to local and market guests. By providing high quality professional services to make every move flawless. </p>

          <h4>House Shifting Services </h4>
          <p>Changing to a new home. Be it within Jaipur or any other part of Jaipur. It can be frustrating, with MyMovers you don't have to worry about anything. 
            Jaipur*'s group of expert* movers and packers ensures all your handling details are packed securely using state-of-the-art equipment. Transport safely and 
            carefully unpack at your new destination. We handle everything from cabinetry and appliances to more fragile details like dining utensils and electronics.</p> 
          <h4>Office Shifting Services</h4>
          <p>Jaipur is home to many multinational companies and start-ups. This creates a general demand for office space in the area. MyMovers Packers and Movers in 
            Jaipur provides technical office moving services designed to reduce time-outs and ensure a smooth transition. Whether you are moving a small office or a 
            large commercial building. We managed to make it perfect. It safely transports your office suite, documents, and cabinets. </p>

          <h4>Packing and Unpacking Services</h4>
          <p>Quality packing is one of the most time-consuming and laborious aspects of the moving process. At MyMovers, we offer unpacking services. Professional 
            quilting ensures all your items are packed safely using high quality supplies such as bubble wrap, custom corrugated and quilted boxes Our section is trained 
            on packing methods to protect fragile and valuable details. Plus, our clearance services ensure you can move into your new home or office quickly. without having 
            to deal with the hassle of clearing it all yourself. </p>

          <h4>Car & Bike Transport Service</h4>
          <p>In addition to office maintenance and relocation, Jaipur also offers vehicle transport from MyMovers Packers and Movers, however bicycle or scooter to your new location. 
            If you want to transport your own car Our specially designed airline guarantees that your vehicle will reach its destination without any damage. </p>
          <h4>Storage Services</h4>
          <p>At times, the timing of your move may not correspond with the availability of your new space, however, MyMovers offers warehouse services. If you need a space to 
            store your belongings for short or long periods of time. Our storage facilities are designed with the most modern security and temperature control systems. 
            This is to keep your goods safe until you are ready to move into your new home or office.</p>

          <h4>Why choose MyMovers Packers and Movers in Jaipur? </h4>
          <p>In highly competitive requests, MyMovers has built on reliability features. professionalism and customer satisfaction, then MyMovers is your goal. 
            Why packers and movers should be in Jaipur.</p>

          <h4>Experienced and Professional Team</h4>
          <p>We are educated and trained hard to handle all types of movement. Whether it is the basic movement within Jaipur or long-distance migration. We value customer 
            satisfaction and go out of our way to ensure that every move is carried out with ease and efficiency. </p>

          <h4>Tailored Moving Plans</h4>
          <p>at MyMovers Packers and Movers We understand that every move is unique. That's why we offer customized moving plans tailored to your specific needs and within budget. 
            Whether you need full moving services or just need help with quilting and shipping. We offer flexible results to meet your requirements. </p>

          <h4>Affordable Pricing </h4>
          <p>Shifting gear can be expensive, but with MyMovers you can get stylish prices for your wealthy. We offer affordable prices without compromising on the quality of service. 
            Our transparent pricing structure ensures that there are no costs or surprises at the end of your move. </p>

          <h4>Safe and Secure Handling of Goods </h4>
          <p>The safety of your goods is our priority. Ours as trusted packers and movers in Jaipur. We take great care to ensure that all details are safely packed and handled 
            carefully throughout the moving process. Our section uses high quality quilting equipment and ultra-modern apparel to ensure your details remain safe during shipping.</p>

          <h4>Insurance Coverage </h4>
          <p>Although we take every palladium to insure the safety of your goods. But sometimes unexpected situations can occur. To give you peace of mind We offer insurance for your items. 
            In case of doubt about damage or loss Our insurance policy guarantees that you will be compensated as a result. </p>

          <h4>How to Book MyMovers Packers and Movers in Jaipur?</h4>

          <p>Our service booking process is easy and hassle-free. So how do you get started here. 

            <li>Request a Quote: Visit our website or call us for a free quote. Provide details about your transfer, such as transfer type (domestic or marketing), transfer size, 
              and destination. </li>

            <li>Get a Customized Plan: Based on hands-on information. Our section will create a customized moving plan to suit your specific needs and budget. </li>

            <li>Schedule the Move: Once you are satisfied with the quote and plan, Give us your moving schedule. Our section will arrive on the agreed upon date to begin 
              the quilting and moving process.</li> 

            <li>Enjoy a Stress-Free Move: Sit back and relax when MyMovers Packers and Movers in Jaipur handles every aspect. of your movement Our category will ensure that your belongings are packed, 
              transported and unpacked with the utmost care.</li> </p>

          <h4>Conclusion</h4>
          <p>Move in a new home or office in Jaipur doesn't have to be stressful. With MyMovers Packers and Movers you can enjoy the experience. Smooth and hassle-free transport professional 
            military platoon comprehensive service and commitment to customer satisfaction making us the top choice For packers and movers in Jaipur, whether you're moving locally or need 
            long distance moving services, MyMovers can help. Notify us now to receive a free quote. And start planning your move without stress!</p>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>
        <br/>
        <h3>Jaipur Office address</h3>
        <b>MyMovers Packers and Movers</b><br/>
        <p style={{marginBottom: '3rem'}}> 
        504, Alankar Plaza, Sector 2, Central Spine, Vidyadhar Nagar,
        Jaipur, Rajasthan 302039 <br />
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9166668371">+91-9166668371</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>

        <CityContainer>
        {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Jaipur;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;