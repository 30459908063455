import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
// import IconWithText from '../components/IconWithText';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';
import '../styles/truck.css';

import {
  FadeInUp,
  // headerHeight,
  BlackButton,
  FadeInRightAnimation,
  FadeInLeftAnimation,
  LeftTextWithImage,
  Text,
  Title,
  Image
} from '../styles/CommonStyles';

import Footer from '../components/Footer';
import theme from '../styles/theme';
// import swal from 'sweetalert';
import DropDownIconAero from '../utils/DropDownIconAero';

function Home(props) {
  const serviceRef = useRef();

  const scrollToService = () => {
    document
      .getElementById('scrollToServices')
      .scrollIntoView({ behavior: "smooth" });
    // window.scrollTo(0, serviceRef.current.offsetTop - 80);
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Helmet>
        <title>Best Packers and Movers | Save Upto 1000 on House Shifting Service</title>
        <meta name="description" content="Trusted movers and packers MyMovers provide efficient house shifting services for a safe, stress-free relocation. Trust us for timely and safe movement!" />
        <meta name="keywords" content="Bangalore, Jaipur, movers, packers, shifting, household" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <StyledContainer>
        <Header color={theme.primary} {...props} />
        <div style={{ width: "100%", height: '100vh' }}>
          <LandingContent>
            <Container2>
              <HeadLine>
                <h1>MyMover - Calculate Moving Cost Online</h1>
                {/* <h1>Calculate Cost Online</h1> */}
                {/* <h4>with the help of our AI based moving cost calculator</h4> */}
              </HeadLine>
              <Features>
                <h4 style={{marginTop: '0.3rem'}}>Click On Calculate Moving Cost Button</h4>
                <h4 style={{marginTop: '0.3rem'}}>Fill Required Details</h4>
                <h4 style={{marginTop: '0.3rem'}}>Select Your household Items & Vehicles</h4>
                <h4 style={{marginTop: '0.3rem'}}>Submit the Form</h4>
                <h4 style={{marginTop: '0.3rem'}}>Get Instant Moving Cost Estimate</h4>
                <h4 style={{marginTop: '0.3rem'}}>Place Your Order</h4>
                <h4 style={{marginTop: '0.3rem'}}>Book Now Online</h4>
              </Features>

              {/* <div>
                <p style={{marginTop: '6rem', marginBottom: 0, fontWeight: 'bold'}}>Fast and reliable</p>
                <h1 style={{marginTop: '12px'}}>Moving Experience</h1>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '9rem'}}>
                  <p style={{fontSize: '1rem', fontWeight: 'bold', marginRight: '2rem'}}>Powered By</p>
                  <img src={"/images/Logos/location-logo.png"} style={{ height: '30px' }} alt="powered-by-google" />
                  <img src={"/images/Logos/payment-logo.png"} style={{ height: '25px', marginLeft: '1rem' }} alt="powered-by-paytm" />
                </div>
              </div> */}
            </Container2>
            <Container1>
              <div className="loop-wrapper" style={{background: theme.primary}}>
                <div className="mountain"></div>
                <div className="hill"></div>
                <Tree className="tree" />
                <Tree className="tree" />
                <Tree className="tree" />
                <Truck className="truck" />
                <Wheels className="wheels" />
              </div>
              <StyledText>
                <TextBelowAnimation>
                  <h4 style={{fontSize: '20px'}}>Get started for a comfortable moving experience</h4>
                </TextBelowAnimation>
                <Link to="/calculate-packers-and-movers-charges">
                  {/* <BlackButton onClick={e => { props.history.push('/login'); }}> */}
                  <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
                </Link>
              </StyledText>
            </Container1>
          </LandingContent>

          <Service>
            <div>Services We Offer</div>
            <AnimateUpDown style={{ minHeight: '40px', cursor: 'pointer' }} onClick={scrollToService}>
              <DropDownIconAero on={false} />
            </AnimateUpDown>
          </Service>
          <StyledTitle ref={serviceRef}>Why Choose MyMover ?</StyledTitle>
          <StyledLeftTextWithImage>
            <Text>
              <ul>
                <li>Relocate your household, car&bike, office and industry</li>
                <li>Calculate your moving cost with our AI based Cost Calculator</li>
                <li>Get Instant Moving Cost Online</li>
                <li>Place your order online</li>
                <li>No hidden charges, Fix Rates</li>
                <li>No Unwanted calls</li>
                <li>Assured Safe and On time delivery</li>
                <li>Simple & Hassle Free Online Booking</li>
                <li>Order Tracking</li>
                <li>Pay For the Services, You Get</li>
              </ul>
            </Text>
            <Image src={"/images/Home/mymover-on-mac.png"} alt="mymover in mac preview" customStyles={'max-width: 400px;@media only screen and (max-width: 500px) { width: 70vw !important; }'} />
          </StyledLeftTextWithImage>
          <div id="scrollToServices"></div>
          <StyledTitle style={{marginTop: 0}} ref={serviceRef}>Household Shifting</StyledTitle>
          <StyledLeftTextWithImage>
            <Text>
              MyMover is a leading household relocating company with  presence in every corner of India. MyMover Packers and Movers deals into 
              household, Corporate, Industrial Packing and Moving. We also deal into Car moving. 
              The shifting is done without any inconvenience and in a hassle free manner. With 
              our experience, expertise and market understanding, we offer fast, reliable and 
              professional packing and moving services in India.
              <br />
              <br />
              Our staff comprises of the 
              skilled labor that is trained to handle all the belongings, commercial or personal, 
              with utmost care. They analyze the requirements for 
              packaging of goods and after that recommend the same to the you. The items 
              are well packed using the specific packaging material.
            </Text>
            <Image src={"/images/Home/household-shifting.svg"} alt="Household shifting" />
          </StyledLeftTextWithImage>
          <StyledTitle>Office Shifting</StyledTitle>
          <StyledLeftTextWithImage>
            <Image src={"/images/Home/office-shifting.svg"} alt="office shifting" />
            <Text>
              MyMover Packers and Movers with well maintained and fully functional facilities, 
              equipment, excellent warehouse and materials provide comprehensive moving solutions
              and relocation services to corporate employees and their transferring families throughout 
              India. In the National Moving arena, we provide highest quality & cost effective 
              Corporate Relocation services to our clients.
              
              <br />
              <br />

              Our central focus in corporate 
              relocation is to provide business organizations with expert systems and services 
              minimize disruption to office functions and thereby to preserve the productivity 
              of employees during the relocation process. At the office relocation site all staff 
              are required to wear MyMover Packers and Movers i-card.
            </Text>
          </StyledLeftTextWithImage>
          <StyledTitle>Vehicle Shifting</StyledTitle>
          <StyledLeftTextWithImage style={{marginBottom: '2rem'}}>
            <Text>
              MyMover is a leading household relocating company with presence all over India. 
              We have presence in every corner of India. MyMover Packers and Movers deals into 
              household, Corporate, Industrial Packing and Moving. We also deal into Car moving. 
              The shifting is done without any inconvenience and in a hassle free manner. With 
              our experience, expertise and market understanding, we offer fast, reliable and 
              professional packing and moving services in India.
              <br />
              <br />
              Our staff comprises of the 
              skilled labor that is trained to handle all the belongings, commercial or personal, 
              with utmost care.We take care of all the relocation details. Packing and moving 
              Services is done with the team of experts. They analyze the requirements for 
              Packing of goods & after that they recommend the same to the customers. The items 
              are well packed using the specific material for packing.
            </Text>
            <Image src={"/images/Home/vehicle-shifting.svg"} alt="Vehicle shifting"  />
          </StyledLeftTextWithImage>
          <Footer />
        </div>
      </StyledContainer>
    </React.Fragment>
  )
}

export default Home;

const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  footer { padding-bottom: 9vh; }

  &:last-child {
    background: url(/images/bg-banner.png);
    background-repeat: no-repeat;
    background-size: 100% 90%;

    @media only screen and (max-width: 500px) {
      background-size: cover;
    }
  }
`;

const LandingContent = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  min-height: 90vh;

  @media only screen and (max-width: 800px) { flex-direction: column; }
`;

const Container1 = styled(FadeInUp)`
  animation: ${FadeInRightAnimation} 0.95s;
  will-change: transform;
  @media only screen and (max-width: 800px) {
    padding-bottom: 10vh;
    .loop-wrapper {
      display: none;
    }
  }
`;

const Container2 = styled.div`
  width: 100%;
  max-width: 400px;
  padding-right: 1rem;
  font-family: 'Inter',sans-serif;
  color: #fff;
  animation: ${FadeInLeftAnimation} 0.95s;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin: 0 auto;
    width: 90%;

    div > h1 { margin-top: 3.5rem; }
  }
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter',sans-serif;

  @media only screen and (max-width: 500px) {
    h4 { display: none; }
  }

  div h4 {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Tree = styled.div`
  position: absolute;
  height: 100px; 
  width: 35px;
  bottom: 0;
`;

const Truck = styled.div`
  background: url(/images/truck.svg) no-repeat;
  background-size: contain;
  height: 60px;

  &:before {
    content: " ";
    position: absolute;
    width: 25px;
    box-shadow:
      -30px 28px 0 1.5px #fff,
      -35px 18px 0 1.5px #fff;
  }
`;

const Wheels = styled.div`
  background: url("/images/wheels.svg") no-repeat;
  height: 15px;
  margin-bottom: 0;
`;

const StyledLeftTextWithImage = styled(LeftTextWithImage)`
  margin: auto;
  padding: 0 1.7rem;
  max-width: 1130px;
  padding-bottom: 4rem;
`;

const StyledTitle = styled(Title)`
  padding: 0 2rem;
  color: ${theme.primary};
  padding-top: 2rem;
`;

const Service = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  flex-direction: column;
  color: ${theme.primary};
  font-weight: bold;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const upAndDownInfinite = keyframes`
  0% { margin-top: -2px; }
  50% { margin-top: 20px; }
  100% { margin-top: -2px; }
`;

const AnimateUpDown = styled.div`
  animation: ${upAndDownInfinite} infinite 1.8s;
`;

const TextBelowAnimation = styled.div`
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 360px;
`;

const Features = styled.div`
  margin-top: 5rem;
  h4 {
    font-size: 20px;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 2.5rem;
    // margin-bottom: 2.7rem;
    margin-left: 10vw;
    h4 {
      font-size: 17px;
      font-family: Inter;
      font-weight: 700;
      // line-height: 13px;
      text-align: left;
      margin-left: 0;
    }
  }
`;

const HeadLine = styled.div`
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  h1 { font-size: 38px; }
  @media only screen and (max-width: 500px) {
    margin-left: 10vw;
    h1 { font-size: 26px; }
    h4 {
      font-weight: 600;
      font-size: 18px;
      display: none;
    }
  }
`;