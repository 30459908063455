import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function About(props) {
  useEffect(() => {
    // document.title = "About Us";
  }, []);

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Helmet>
        <title>Best Packers and Movers | Save Upto 1000 on House Shifting Service</title>
        <meta name="description" content="Trusted movers and packers MyMovers provide efficient house shifting services for a safe, stress-free relocation. Trust us for timely and safe movement!" />
        <meta name="keywords" content="Bangalore, Jaipur, movers, packers, shifting, household" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '2rem'
        }}>About Us</h1>

        <p>
          We are, simply put, a group of individuals sharing a common vision of 
          ushering in a new wave of change in the way the Indian relocation industry 
          operates by bringing in the much needed standardization of various parameters 
          including safety, accountability, professionalism and unmatched service 
          experience at affordable rates for packers and movers.
        </p>

        <p>
          MyMover, conceived as the brainchild of a group of enterprising young men in April 
          2015, operates as a move manager service platform where customers can search for a 
          tailor-made solution for their relocation needs. Founded and managed by IIT alumni, 
          MyMover’s mission is to make every move happening in India be a hassle-free 
          #happyshifting experience to bring in customer delight.
        </p>

        <p>
          The most hassle-free move booking experience is made possible by the world’s first 
          ever true instant relocation charge auto-quoting engine developed. This technological 
          marvel <a href="/calculate-packers-and-movers-charges">calculates your move quote
          </a> after you enter your move details. The system-generated 
          quote makes the pricing completely transparent. The additional option of customizing 
          the quote online truly demonstrates that customer is indeed, the king. MyMover is the 
          only platform where the customer is encouraged to benefit from the dynamic pricing nature 
          of the packers and movers industry.
        </p>

        <p>
          Unlike a marketplace where your requirement is passed over to any vendor, MyMover truly 
          cares about your move and deploys specially trained teams for the service execution. Teams 
          are trained on best practices followed in packing, unpacking, handling, transportation, 
          safety, etiquette and operating technology. May it be house shifting or vehicle shifting, 
          the teams are well equipped and trained to safely handle your belongings during loading, 
          unloading and transportation.
        </p>

        <p>
          As the flagship product of vCorps, MyMover is the ultimate one-stop solution for your 
          queries such as “How can I find the most trusted and reliable packers and movers”, “best 
          packers and movers for house shifting”, “trusted and safe truck hire for house and office 
          shifting”, “who is the most trusted packers and movers in India”, etc.
        </p>

        <p style={{fontWeight: 600}}>
          #happyShifting <br />

          #happyMyMover
        </p>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default About;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 5rem;
  font-family: Montserrat, serif;

  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;

  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;